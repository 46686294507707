import { ApexOptions } from 'apexcharts';
import { ValueResponse } from 'entities/dataValue';
import { CategoryDiagramSettings } from 'entities/panel';
import { defined } from 'shared/lib/checks';
import { getSeriesValue } from './getSeriesValue';
import { isPieChart } from './isPieChart';
import { isStackedChart } from './isStackedChart';

export type BaseData = {
  x: string;
  y: number | null;
  meta?: {
    groupId: string;
    nodeId?: string;
  };
};

export type BaseSeries = {
  name: string;
  data: BaseData[];
};

export type ApexChartRow = ApexAxisChartSeries[0];

export const parseSeries = (response: ValueResponse, config: CategoryDiagramSettings): ApexOptions['series'] => {
  const fractions = config.visuals.dataLabels?.value?.fraction ?? 0;
  const groups = response.byGroups;

  if (isPieChart(config)) {
    return groups[0]?.byIndicators.map((indicator) => getSeriesValue(indicator, fractions)) || [];
  }

  const getClassifierTitle = (innerId: string) => {
    return response.classifiers?.find((classifier) => classifier.innerId === innerId)?.title ?? '';
  };

  if (isStackedChart(config)) {
    const stackedResult: ApexChartRow[] = [];
    groups.forEach((group) => {
      group.byIndicators.forEach((indicator) => {
        if (indicator.nodeIds?.length) {
          const name = getClassifierTitle(defined(indicator.nodeIds[0]).nodeId);
          const data = [getSeriesValue(indicator, fractions)];

          if (data[0]) {
            stackedResult.push({ name, data });
          }
        }
      });
    });

    return stackedResult;
  }

  const resultObj: Record<string, ApexChartRow> = {};

  groups.forEach((group, index) => {
    group.byIndicators.forEach((indicator) => {
      const key = indicator.key;
      const name = getClassifierTitle(defined(indicator.nodeIds?.[0]).nodeId);

      const value = {
        y: getSeriesValue(indicator, fractions),
        x: name,
        meta: { groupId: group.nodeId },
      };

      if (value.y) {
        if (key in resultObj && resultObj[key]) {
          const item = resultObj[key] as ApexChartRow;
          item.data[index] = value;
        } else {
          resultObj[key] = {
            name: name,
            group: name,
            data: [value],
          };
        }
      }
    });
  });

  return Object.values(resultObj);
};
