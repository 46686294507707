import { getTimeDiagramValues } from 'entities/chart/api';
import { EventOptions } from 'entities/chart/model';
import { Classifier, ClassifierNode } from 'entities/classifier';
import contextStore from 'entities/context/contextStore';
import { ValueResponse } from 'entities/dataValue';
import { getValues } from 'entities/dataValue/api';
import { CategoryDiagramSettings } from 'entities/panel';
import panelsStore from 'entities/panel/panelsStore';
import { DateTime } from 'luxon';
import { Props } from 'react-apexcharts';
import { defined } from 'shared/lib/checks';
import { chartTypeMapper, parseOptions } from './parseOptions';
import { parseRequest } from './parseRequest';
import { BaseSeries, parseSeries } from './parseSeries';
import { parseTimeSeries } from './parseTimeSeries';
import { isPieChart } from './isPieChart';

const isTimeSeriesResponse = (
  response: ValueResponse | ValueResponse[],
  config: CategoryDiagramSettings
): response is ValueResponse[] => {
  return Boolean(config.filters?.byPeriod.enabled);
};

export const generateConfig = async ({
  blockId,
  config,
  replaceNodes,
  currentDate,
  currentClassifierId,
  currentRootClassifier,
  parentNode,
}: {
  blockId: string;
  config: CategoryDiagramSettings;
  replaceNodes?: ClassifierNode[];
  currentDate?: DateTime;
  currentClassifierId?: string;
  currentRootClassifier?: Classifier;
  parentNode?: HTMLDivElement;
}): Promise<Props | undefined> => {
  const updateDrillDownHistory = (nodeId?: string) => {
    const updatedConfig = JSON.parse(JSON.stringify(config)) as CategoryDiagramSettings;

    const currentHistory = updatedConfig.temporary.history?.find((history) =>
      history.rootId.includes(currentRootClassifier?.innerId ?? '')
    );

    if (nodeId) {
      if (currentHistory) {
        currentHistory.nodeId = [...defined(currentHistory.nodeId), nodeId];
      }

      const isHistoryEmpty = updatedConfig.temporary.history?.every(
        (history) => history.rootId !== currentRootClassifier?.innerId
      );
      if (!updatedConfig.temporary.history || isHistoryEmpty) {
        updatedConfig.temporary = {
          ...updatedConfig.temporary,
          history: [
            ...(updatedConfig.temporary.history ?? []),
            { rootId: updatedConfig.temporary.currentRootClassifier ?? '', nodeId: [nodeId] },
          ],
        };
      }
    } else {
      if (updatedConfig.filters) {
        updatedConfig.temporary.history = [];
      }
    }

    void panelsStore.updateBlockSettings(defined(contextStore.currentContextId), blockId, updatedConfig);
  };

  const updatedConfig = (replaceNodes?: ClassifierNode[]) => {
    const _config = JSON.parse(JSON.stringify(config)) as CategoryDiagramSettings;
    if (!_config.filters) {
      return _config;
    }

    if (replaceNodes?.length) {
      replaceNodes.forEach((replaceNode) => {
        defined(_config.filters?.nodes.find((node) => node.rootId === replaceNode.rootId)).nodeId = replaceNode.nodeId;
      }, []);
    }

    return _config;
  };

  const request = parseRequest(
    updatedConfig(replaceNodes),
    currentDate,
    currentClassifierId,
    currentRootClassifier?.innerId
  );

  if (!request) {
    return;
  }

  const response: ValueResponse | ValueResponse[] = config.filters?.byPeriod.enabled
    ? await getTimeDiagramValues(defined(contextStore.currentContextId), request)
    : await getValues(defined(contextStore.currentContextId), request);
  const series = config.filters?.byPeriod.enabled
    ? parseTimeSeries(
        response as ValueResponse[],
        config.filters.splitterValues,
        config.visuals.dataLabels?.value?.fraction
      )
    : parseSeries(response as ValueResponse, config);

  const updateSeries = (options: EventOptions) => {
    const serie = series?.[options.seriesIndex] as BaseSeries;
    const data = serie.data[options.dataPointIndex];
    const isDrillDownEnd =
      !!config.classifiers.drillDown.drillDownOrder?.length &&
      replaceNodes?.some((replaceNode) => replaceNode.nodeId === data?.meta?.groupId);

    if (isDrillDownEnd) {
      return;
    }

    updateDrillDownHistory(data?.meta?.groupId);
  };

  const onlyUnique = (value: string, index: number, array: string[]) => {
    return array.indexOf(value) === index;
  };

  const getNodeTitles = () => {
    if (isTimeSeriesResponse(response, config)) {
      return [];
    }

    // if chart has many columns
    const groups = response.byGroups;
    if (groups.length > 1 || groups[0]?.nodeId) {
      return groups.map((group) => defined(group.nodeTitle)).filter(onlyUnique);
    }

    // if chart has only one column and selected classifier from folder
    if (currentClassifierId) {
      return [response.classifiers?.find((c) => c.innerId === currentClassifierId)?.title ?? ''];
    }

    // if chart has only one column
    return [currentRootClassifier?.title ?? ''] as [string];
  };

  const options = parseOptions(config, getNodeTitles(), updateSeries, parentNode);
  if (isPieChart(config)) {
    const res = response as ValueResponse;
    const ids = res.byGroups[0]?.byIndicators.map((indicator) => indicator.nodeIds?.[0]?.nodeId) || [];

    const labels: string[] = [];
    ids.forEach((id) => {
      if (id) {
        const classifier = res.classifiers?.find((c) => c.innerId === id);
        if (classifier) {
          labels.push(classifier.title);
        }
      }
    });
    options.labels = labels;
  }

  const result: Props = {
    type: chartTypeMapper.get(config.visuals.type)?.type,
    series: series,
    options: options,
    gridLineWidth: config.visuals.gridLineType?.width ?? '2',
  };

  return result;
};
